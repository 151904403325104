import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import HeroInner from '../components/heroinner'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import { Card, CardDeck, Row, Container } from 'react-bootstrap'
import '../styles/blog.css'

const BlogPage = (props) => {
  const postList = props.data.allMarkdownRemark;
  return (
    <Layout>
      <SEO title='Blog' description="Read about SEO, Digital Marketing, and Branding articles and tips to get your website producing traffic, leads, and sales" />
      <HeroInner title="Blog" />
      <h2 className="text-center mt-5">Blog Articles</h2>
      <Container>
        <CardDeck className="py-5 justify-content-center">
          {postList.edges.map(({ node }, i) => (
            <div className="post-list my-3">
              <Link className="no-underline" to={`/blog${node.fields.slug}`} aria-label={`${node.frontmatter.title} article link`}>
                <Card className="shadow w-fixed card-center">
                  <Row className="justify-content-center py-2">
                    <Img className="card-img" fluid={node.frontmatter.image.childImageSharp.fluid} />
                  </Row>
                  <Card.Title className="text-center underline">
                    {node.frontmatter.title}
                  </Card.Title>
                  <span className="text-center light-text">{node.frontmatter.date}</span>
                  <Card.Body className="text-center light-text">
                    {/* <p>{node.excerpt}</p> */}
                  </Card.Body>
                </Card>
              </Link>
            </div>        
            ))}
        </CardDeck>
      </Container>
    </Layout>
  )
}

export default BlogPage;

export const listQuery = graphql`
  query ListQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          fields{
            slug
          }
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM Do YYYY")
            title
            image{
              childImageSharp{
                fluid (maxWidth: 200){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`